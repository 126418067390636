<template>
	<div class="node-overview overview-closed" id="node-overview"> 
		<div class="container">
			<div class="controls">
				<ButtonLoader :hideVal="hideRow" />
				<ButtonLoader :hideVal="hideRow" />
				<a class="close" :class="{displayBtns : !hideRow}" href="#" title="Close" @click.prevent="closeNodeOverview()" v-if="this.isViewingBlog == false && this.getSocial.currentPageSectionThatIsOpen === ''">
					<i class="fas fa-times"></i>
				</a>
				<ul class="btn-list" :class="{displayBtns : !hideRow}">
					<li v-if="(can(getAuthData, 'update_own_client_contentTree') && getUserClientRelationship(getAuthData, getClientSlug)) || (can(getAuthData, 'update_other_client_contentTree') && !getUserClientRelationship(getAuthData, getClientSlug))">
						<a class="btn-save btn-save--outline edit" href="#" title="Edit" @click.prevent="openAddNode()"><i class="fa-duotone fa-pen-to-square theme-main"></i>Edit node</a>
					</li>
					<li v-if="(can(getAuthData, 'update_own_client_contentTree') && getUserClientRelationship(getAuthData, getClientSlug)) || (can(getAuthData, 'update_other_client_contentTree') && !getUserClientRelationship(getAuthData, getClientSlug))">
						<a class="btn-save add" href="#" title="add Child" @click.prevent="addChildNode()"><i class="fa-duotone fa-circle-plus theme-secondary"></i>Add new</a>
					</li>
				</ul>
			</div>
			<!-- end controls -->
			<div class="contents">
				<div class="row-container">
						<RowsLoader :hideVal="hideRow" />
						<div class="row" :class="{displayRows : !hideRow}" v-for="(fieldGroup, key) in filteredFieldArray()" v-bind:key="key">
						<div v-for="(field, key) in fieldGroup.fields" v-bind:key="key">
							<div class="item grid-container" v-if="key !== 'hideFromUser' && field?.value" :class="{isFormat : key == 'format'}">
								<div class="col heading">
									<p>{{ field.label }}</p>
								</div>
								<!-- end col -->
								<div class="col field">
									<template v-if="key == 'assign'">
										<p>{{ field.value.fullName }}</p>
									</template>
									<template v-if="key == 'format'">
										<p>{{ field.value }}</p>
										<button v-if="isBlogArticle" @click.prevent="onViewBlog" class="btn-primary btn-primary--outline btn-link-overview">{{ getOpenBlogEditor ? 'Close' : 'View' }}<i class="fa-solid fa-angle-right"></i></button>
										<button v-if="isSocialPost" @click.prevent="onViewSocial" class="btn-primary btn-primary--outline btn-link-overview">{{ getSocial.currentPageSectionThatIsOpen ? 'Close' : 'View' }}<i class="fa-solid fa-angle-right"></i></button>
									</template>
									<template v-if="typeof field.value === 'string' && key !== 'format'">
										<p :class="field.label" v-if="field.label === 'Deadline' || field.label === 'Created'">{{ new Date(field.value).toLocaleString("en-GB") }}</p>
										<p :class="field.label" v-else>{{ field.value }}</p>
									</template>
								</div>
							</div>
							<!-- end item -->
						</div>
					</div>
				</div>
				<!-- end row -->
				<div v-if="fieldStatus === 'Pending Review' && mainUsername === currentUsername" class="controls controls-approval">
						<div class="controls">
						<ButtonLoader :hideVal="hideRow" />
						<ButtonLoader :hideVal="hideRow" />
						<ul class="btn-list" :class="{displayBtns : !hideRow}">
							<li>
								<button class="btn-close" @click.prevent="rejectNode()"><i class="fa-solid fa-xmark-large"></i>Reject</button>
							</li>
							<li>
								<button class="btn-save" @click.prevent="approveNode()"><i class="fa-solid fa-check"></i>Approve</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!-- end contents -->
		</div>
		<!-- end container -->
	</div>
	<!-- end node-overview -->
</template>

<script>
import {mapGetters} from "vuex";
import RowsLoader from '../tools/loaders/RowsLoader.vue';
import ButtonLoader from '../tools/loaders/ButtonLoader.vue';

let main = null
let nodeOverview = null
let nodeForm = null

export default {
	components: {
		RowsLoader,
		ButtonLoader
	},
	data() {
		return {
			sortedRows: {},
			isViewingBlog: false,
			isViewingSocial: false,
			hideRow: true,
			mainUsername: '',
			currentUsername: ''
		}
	},
	computed: {
		...mapGetters("client", ["getCurrentNode", "getClientData", "getClientContentTree", "getClientSlug", "getOpenBlogEditor", "getSocial", "getOpenBlogEditorAddNodeForm", 'getRemoveRowLoader', 'getCloseNodeOverview']),
		...mapGetters("auth", ["getAuthData"]),
		fieldStatus(){
			return this.getCurrentNode.fieldGroups.status.fields.status.value;
		},
		isBlogArticle(){
			let fieldGroupsArray = Object.values(this.getCurrentNode.fieldGroups);
			let field = fieldGroupsArray.filter((item) => item.title === 'Format');
			let field2 = fieldGroupsArray.filter((item) => item.title === 'Status');
			let val = false;
			if(field[0].fields.format.value === 'Blog' && field2[0].fields.status.value !== 'Category'){
				val = true;
			}
			return val;
		},
		isSocialPost(){
			let fieldGroupsArray = Object.values(this.getCurrentNode.fieldGroups);
			let field = fieldGroupsArray.filter((item) => item.title === 'Format');
			let field2 = fieldGroupsArray.filter((item) => item.title === 'Status');
			let val = false;
			if(field[0].fields.format.value === 'Social' && field2[0].fields.status.value !== 'Category'){
				val = true;
			}
			return val;
		},
	},
	watch:{
		'getAuthData': {
			handler() {
				this.currentUsername = this.getAuthData.username;
			}
		},
		'getClientData.mainContact.username': {
			handler() {
				this.mainUsername = this.getClientData.mainContact.username;
			}
		},
		'isBlogArticle': {
			async handler(val) {
				try{
					if(val){
						await this.ifFormatSetInParam();
					}
				}catch(error){
					console.log('nodeOverview is isBlogArticle catch error', error);
				}
			}
		},
		'isSocialPost': {
			async handler(val) {
				try{
					if(val){
						await this.ifFormatSetInParam();
					}
				}catch(error){
					console.log('nodeOverview is isBlogArticle catch error', error);
				}
			}
		},
		hideRow(val){
			console.log('hideRow changed:', val);
		},
		getOpenBlogEditor(val){
			if(val === false){
				this.isViewingBlog = false
			}
		},
		getSocial(val){
			if(val.openSocial === false){
				this.isViewingSocial = false
			}
		},
		getRemoveRowLoader(val){
			console.log('REMOVE ROW LOADER', val);
			if(val === true){
				this.removeLoader();
			}
			this.$store.commit('client/setRemoveRowLoader', false);
		},
		getCloseNodeOverview(){
			if (nodeOverview.classList.contains("overview-open")) {
				main.classList.remove("overview-open")
				main.classList.add("overview-closed")
				nodeOverview.classList.remove("overview-open")
				nodeOverview.classList.add("overview-closed")
			}
		},
		getCurrentNode(){
			this.removeLoader();
			this.loadEditor();
		},
	},
	methods: {
		removeLoader(){
		
			let intervalId = setInterval(() => {
				
				const row = document.querySelector(".row-container .col.field p.Status");
				const row2 = document.querySelector(".row-container .col.field p.Created");
				let ready = false;
				if(row && row2){
					ready = true;
				}
				if(ready){
		
					this.hideRow = false;
					clearInterval(intervalId);
				}
			}, 1000);

		},
		async onViewBlog(){
			try{
				const isNodePosting = await this.$store.dispatch("client/isNodePosting");
				if(isNodePosting.status === false){
					this.isViewingBlog = !this.getOpenBlogEditor;
					this.$store.commit("client/setOpenBlogEditor", this.isViewingBlog);	
					this.setNodeViewFormat(this.isViewingBlog)
				}else{
					alert(isNodePosting.error);
				}
			}catch(error){
				console.log('openAddNode catch error', error);
			}
		},
		async onViewSocial(){
			try{
				const isNodePosting = await this.$store.dispatch("client/isNodePosting");
				if(isNodePosting.status === false){
					if(this.getSocial.currentPageSectionThatIsOpen){
						this.$store.commit("client/setSocial", {prop: 'currentPageSectionThatIsOpen', val: ''});	
						this.setNodeViewFormat(false);
					}else{
						this.$store.commit("client/setSocial", {prop: 'currentPageSectionThatIsOpen', val: 'overview'});
						this.setNodeViewFormat(true);
					}	
				}else{
					alert(isNodePosting.error);
				}
			}catch(error){
				console.log('openAddNode catch error', error);
			}
		},
		filteredFieldArray() {
			// Turn fieldGroups into iterable aray
			let fieldGroupsArray = Object.values(this.getCurrentNode.fieldGroups);

			// filteredFieldArray.status.fields.status.value
			
			// Filter out fields that are hidden
			let nonHiddenFields = fieldGroupsArray.filter((item) => !item.fields.hideFromUser.value === true)
			// Filter out fieldGroups that have all empty fields
			let nonEmptyFields = nonHiddenFields.filter((fieldGroup) => {
				let fieldsArray = Object.values(fieldGroup.fields)
				for (let field of fieldsArray) {
					if (field.label !== "Hide from user") {
						if (field?.value) {
							return fieldGroup
						}
					}
				}
			})
			return nonEmptyFields.reverse()
		},
		async closeNodeOverview() {
			try{
				this.$store.commit('client/setOldNodeId', this.getCurrentNode.id);
				this.$store.commit('client/setNodeWasOrIsPosting', {status: false, error: ''});
				this.$store.commit('client/clearBlogData');
				if (nodeOverview.classList.contains("overview-open")) {
					main.classList.remove("overview-open")
					main.classList.add("overview-closed")
					nodeOverview.classList.remove("overview-open")
					nodeOverview.classList.add("overview-closed")
				}
				await this.$store.dispatch("client/clearSelectedNode")
				this.$store.commit("client/setOpenKeywordPlanner", false);
				this.$store.commit("client/setOpenBlogEditor", false);
				this.$store.commit("client/setOpenBlogEditorAddNodeForm", false);
				this.hideRow = true;
				console.log('setting this.hideRow to true');
				this.$store.commit('client/setZoomToFitContentTree');
			}catch(error){
				console.log('closeNodeOverview catch error', error);
			}
		},
		async openAddNode() {
			try{
				const isNodePosting = await this.$store.dispatch("client/isNodePosting");
				const isNodeLocked = await this.$store.dispatch('client/isNodeLocked', this.getCurrentNode.id);
				console.log('openAddNode-step-1', [isNodePosting, isNodeLocked]);
				if(isNodePosting.status === false && this.getCurrentNode.category !== 'root' && isNodeLocked === false){
					if (nodeForm.classList.contains("form-closed")) {
						main.classList.remove("node-form-closed")
						main.classList.add("node-form-open")
						nodeForm.classList.remove("form-closed")
						nodeForm.classList.add("form-open")
					}
					this.$store.commit('client/setAddNodeFormOpen', true);
					this.$store.commit("client/setOpenKeywordPlanner", false);
					this.$store.commit("client/setOpenBlogEditor", false);
					this.$store.commit('client/setOpenaddNodeClickTitle', true);
					this.hideRow = true;
					if(this.getCurrentNode.id){
						await this.$store.dispatch('client/updateNodeActionRecord', {nodeId: this.getCurrentNode.id, action: 'ENTER'})
					}
				}else{
					if(isNodePosting.status === true && isNodeLocked === false){
						console.log('openAddNode-step3');
						alert(isNodePosting.error);
					}
					if(this.getCurrentNode.category === 'root'){
						alert('You cannot edit the root node');
					}
				}
			}catch(error){
				console.log('openAddNode catch error', error);
			}
		},
		async addChildNode() {
			try{
				let currentNode = this.$store.getters["client/getCurrentNode"];
				if(currentNode.level >= 8){
					alert('You cannot nest anymore than 8 nodes')
				}else{
					this.$store.commit('client/setOldNodeId', currentNode.id);
					let author = this.getAuthData.fullName;
					const isNodePosting = await this.$store.dispatch("client/isNodePosting");
					if(isNodePosting.status === false){
						this.$store.dispatch("client/addNewNode", {parentNodeId: currentNode.id, author});
						this.openAddNode()
						this.$store.commit('client/setIsCreatingNewNode', true);
						this.$store.commit('client/setIsCreatingNewNodeToAddToDB', true);

						this.$store.commit('client/setAddNodeFormOpen', true);
						this.$store.commit("client/setOpenKeywordPlanner", false);
						this.$store.commit("client/setOpenBlogEditor", false);
						this.$store.commit("client/setOpenBlogEditorAddNodeForm", false);
						this.hideRow = true;
					}else{
						alert(isNodePosting.error);
					}
				}
			}catch(error){
				console.log('addChildNode catch error', error);
			}
		},
		async ifFormatSetInParam(){
			if (this.$route.query?.format === 'true') {
				console.log('this.$route.query?.format:', [this.$route.query?.format]);
				if(this.isBlogArticle){
					this.onViewBlog();
				}
				if(this.isSocialPost){
					this.onViewSocial();
				}
			}
		},
		setNodeViewFormat(data){
			if(data === true || data === false){
				// Get the current URL
				let url = new URL(window.location.href);
				// Get the search parameters from the URL
				let searchParams = url.searchParams;
				// Set the new parameter
				searchParams.set('format', data);
				// Update the URL with the new parameter
				window.history.pushState({}, '', url.toString());
			}
		},
		async approveNode(){
			try{
				this.$store.commit('client/setHidePageLoader', false);
				await this.$store.dispatch('client/nodeApproval', true);
				this.$store.commit('client/setReloadContentTree', true);
			}catch(error){
				console.log('approveNode catch error', error);
			}
		},
		async rejectNode(){
			try{
				this.$store.commit('client/setHidePageLoader', false);
				await this.$store.dispatch('client/nodeApproval', false);
				this.$store.commit('client/setReloadContentTree', true);
			}catch(error){
				console.log('rejectNode catch error', error);
			}
		},
		async loadEditor() {
			const url = new URL(window.location.href);
			if (url.searchParams.get('load-editor') === 'true') {
				console.log('load the editor');
				this.openAddNode();

				// Remove 'load-editor' from URL
				url.searchParams.delete('load-editor');
				window.history.replaceState(null, '', url.toString());
			}
		}
	},
	async mounted() {
		try{
			main = document.getElementById("main")
			nodeForm = document.getElementById("add-node-form")
			nodeOverview = document.getElementById("node-overview")
			this.currentUsername = this.getAuthData.username ? this.getAuthData.username : '';
			this.mainUsername = this.getClientData.mainContact && this.getClientData.mainContact.username ? this.getClientData.mainContact.username : '';
			const url = new URL(window.location.href);
			url.searchParams.get('format') === 'true' ? this.setNodeViewFormat(true) : 	this.setNodeViewFormat(false);
			this.removeLoader();
		}catch(error){
			console.log('nodeOverview mounted error', error);
		}
	},
}
</script>
<style lang="scss" scoped>
@import "./../../assets/scss/components/nodeOverview.scss";
@import "./../../assets/scss/components/blog.scss";
</style>
